import React from "react"
import SEO from "../../components/seo"

import Scene from "../../components/scenes/scene-wall-dest";
import LayoutScene from "../../components/layout-scene";


const Interactive = () => (
    <LayoutScene>
        <SEO title="WebGL" />
        <div style={{position:`relative`, margin: `0`, padding:`0`, border:`0`}}>
            <Scene />
        </div>
    </LayoutScene>
)

export default Interactive