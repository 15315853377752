import React, { useRef } from 'react';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { ExecuteCodeAction } from '@babylonjs/core/Actions';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import { Vector3 } from '@babylonjs/core/Maths/math';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';

import {
    CannonJSPlugin,
    GlowLayer,
    InterpolateValueAction,
    PhysicsImpostor,
    StandardMaterial,
    Texture,
    Matrix, FollowCamera
} from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";
import {PointLight} from "@babylonjs/core";

import {Mesh} from "@babylonjs/core";  // ???

import {ArcRotateCamera} from "@babylonjs/core";
import {SceneLoader} from "@babylonjs/core";

let babylonLink;
let fighter;

const onSceneReady = scene => {

    scene.clearColor = new Color3(1, 1, 1); // CHECK
    const canvas = scene.getEngine().getRenderingCanvas();

    window.CANNON = require('cannon')


// This creates a basic Babylon Scene object (non-mesh)
    const gravityVector = new Vector3(0, -9.8, 0);
    const physicsPlugin = new CannonJSPlugin();
    scene.enablePhysics(gravityVector, physicsPlugin);

    // var camera = new FreeCamera("camera1", new Vector3(0, 10, -20), scene);
    // camera.setTarget(Vector3.Zero());
    // camera.attachControl(canvas, true);

    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
    light.intensity = 0.7;

    var box = MeshBuilder.CreateBox("box", {diameter: 2, segments: 32}, scene);
    box.position.y = 5;
    box.position.z = -10;
    box.physicsImpostor = new PhysicsImpostor(box,PhysicsImpostor.BoxImpostor,
        { mass: 2, friction: 0.01, restitution: 0 }, scene);
    box.checkCollisions = true;
    box.physicsImpostor.physicsBody.angularDamping = 0.9;



    fighter = SceneLoader.ImportMesh("",
        "https://raw.githubusercontent.com/FTi130/gatsby-site/master/src/components/assets/",
        "star-wars-vader-tie-fighter.babylon",
        scene,
        function(newMeshes)
        {
            newMeshes[0].checkCollisions = true

            for(let i = 0; i < newMeshes.length; i++) {

                console.log(newMeshes[i]);
                newMeshes[i].checkCollisions = true;
            }

            var smth = newMeshes[0];
            console.log('HERE I AM A FIGHTER')
            console.log(smth);
            var sphere = newMeshes[0].getChildMeshes()[0];
            sphere.checkCollisions = true;
            //console.log(sphere)
            sphere.checkCollisions = true;
        });

    // fighter.position.y = 7;
    // fighter.position.z = -10;
    // fighter.physicsImpostor = new PhysicsImpostor(fighter,PhysicsImpostor.BoxImpostor,
    //     { mass: 2, friction: 0.01, restitution: 0 }, scene);
    // fighter.checkCollisions = true;
    // fighter.physicsImpostor.physicsBody.angularDamping = 0.9;



    var ground = MeshBuilder.CreateGround("ground", {width: 1000, height:1000}, scene);
    ground.physicsImpostor = new PhysicsImpostor(ground, PhysicsImpostor.BoxImpostor, { mass: 0, friction: 0.05, restitution: 0.7 }, scene);
    ground.checkCollisions = true;
    // ground.material =  new GridMaterial("mat", scene);

    var box1 = Mesh.CreateBox("box1", 5, scene);
    box1.rotation.x = Math.PI/3;
    box1.position.y -= 1;
    box1.physicsImpostor = new PhysicsImpostor(box1,PhysicsImpostor.BoxImpostor,
        { mass: 0, friction: 0.01, restitution: 0 }, scene);

    var box1 = Mesh.CreateBox("box1", 5, scene);
    box1.rotation.x = Math.PI/3;
    box1.position.y -= 1;
    box1.physicsImpostor = new PhysicsImpostor(box1,PhysicsImpostor.BoxImpostor,
        { mass: 0, friction: 0.01, restitution: 0 }, scene);

    var meshes = [];
    for (var x=0;x<11;x++) {
        for (var z=0;z<11;z++) {
            var box1 = MeshBuilder.CreateBox("box",
                {width: 4.9, height: 4.9, depth: 4.9}, scene);
            box1.position.x = (x-5)*6;
            box1.position.y = 5 + z*5;
            box1.position.z = 100; // (25+z)*5;
            meshes.push(box1);
        }
    }

    for (var i=0;i<meshes.length;i++) {
        meshes[i].physicsImpostor = new PhysicsImpostor(meshes[i],
            PhysicsImpostor.BoxImpostor,
            { mass: 5, friction: 0.01, restitution: 0  }, scene);

    }


    var transformForce = function (mesh, vec) {
        var mymatrix = new Matrix();
        mesh.rotationQuaternion.toRotationMatrix(mymatrix);
        return Vector3.TransformNormal(vec, mymatrix);
    };

    var rotate = function (mesh, direction, power) {
        // console.log("rotate happening", direction.scale(power));
        mesh.physicsImpostor.setAngularVelocity(
            mesh.physicsImpostor.getAngularVelocity().add(
                direction.scale(power)
            )
        );
    }

    var translate = function (mesh, direction, power) {
        mesh.physicsImpostor.setLinearVelocity(
            mesh.physicsImpostor.getLinearVelocity().add(
                transformForce(mesh, direction.scale(power))
            )
        );
    }

    var mf = false;
    var mb = false;
    var rl = false;
    var rr = false;

    var oKeyBoard = {
        bPressRight: false,
        bPressLeft: false,
        bPressUp: false,
        bPressDown: false
    };


    window.addEventListener('keydown',function (e) {
        switch (e.keyCode) {
            case 87://w
                mf = true;
                break;
            case 83://s
                mb = true;
                break;
            case 65://a
                rl = true;
                break;
            case 68://d
                rr = true;
                break;
        }
    });
    window.addEventListener('keyup',function (e) {
        switch (e.keyCode) {
            case 87://w
                mf = false;
                break;
            case 83://s
                mb = false;
                break;
            case 65://a
                rl = false;
                break;
            case 68://d
                rr = false;
                break;
        }
    });


    function update() {
        if (mf == true) {
            translate(box, new Vector3(0, 0, 1), 0.5);
        }
        if (mb == true) {
            translate(box, new Vector3(0, 0, -1), 0.5);
        }
        if (rl == true) {
            rotate(box, new Vector3(0, -1, 0), 0.2);
        }
        if (rr == true) {
            rotate(box, new Vector3(0, 1, 0), 0.2);
        }
    }

    scene.registerBeforeRender(function() {
        update();
    });





    // Create a camera which rotates around the object
    // var camera = new ArcRotateCamera("camera", 0, Math.PI/2, 15, Vector3.Zero(), scene);

    // const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    // camera.attachControl(canvas, true);






}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = scene => {
//     if (box !== undefined) {
//         var deltaTimeInMillis = scene.getEngine().getDeltaTime();
//
//         const rpm = 10;
//         box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
//         box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));
//
//     }
 }

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://userpav.netlify.app/">
            </a>
        </>
    )
}
